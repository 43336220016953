import React from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

const titles = {
	// Clientes
	clientes_distintos_resumo: 'Clientes distintos são aqueles que não se repetem.',

	// Fábrica de planos
	fabrica_planos_new_valores: '“É importante o preenchimento dos campos “Corte”, “Barba” e “Corte + Barba” com os valores sugeridos.',

	// Prévia
	previa_new_filtro: 'Filtre o mês INTEIRO para gerar a sua prévia e fazer o acompanhamento da performance de seus profissionais.',
	previa_new_servicos: 'Aqui mostra a soma dos avulsos e todos os extras.',

	// Presentes
	presente_new_tempo_assinatura: 'Preencha aqui quanto tempo de assinatura o cliente precisa ter para receber o presente.',

	// Relatórios
	relatorio_2_filtro: 'Para utilizar este relatório, preencha da DATA INICIAL até a FINAL para escolher o período a ser analisado.\n⁠Em seguida, escolha a DATA DE RETORNO, que é ATÉ QUAL data deseja saber se o cliente retornou à barbearia.\nExemplo de filtro:\n⁠Para verificar quais clientes vieram em AGOSTO e retornaram ATÉ 07 de SETEMBRO, o filtro seria:\n⁠Data inicial 01/08/2024\n⁠Data final 31/08/2024\nData final de retorno 07/09/2024',
	relatorio_2_data_inicial: 'Período INICIAL que os clientes frequentaram a barbearia.',
	relatorio_2_data_final: 'Período FINAL que os clientes frequentaram a barbearia.',
	relatorio_2_data_retorno: 'Período onde mostra quantos clientes RETORNARAM até a data selecionada.',
}

export default function BetterTooltip(props) {
	if (!props.title) {
		props.title = "empty";
	}
	return (
		<OverlayTrigger placement={props.placement ?? 'top'} trigger={['focus']}
			overlay={
				<Tooltip className="mt-2 mb-2" >
					{titles[props.title].includes('\n') ?
						titles[props.title].split('\n').map(line => (
							<p style={{ margin: 0 }} >{line}</p>
						))
						:
						<p style={{ margin: 0 }} >{titles[props.title]}</p>
					}
					{props.link ?
						<a href={props.link} target="_blank" >{props.link}</a>
						:
						null
					}
				</Tooltip>
			} >
			<i tabIndex="0" style={{ cursor: "pointer", ...props.iconStyle }} className={`fas fa-info-circle ${props.iconClass ?? ''}`}></i>


		</OverlayTrigger>
	)
}